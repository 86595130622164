@charset "utf-8";

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
 -webkit-appearance: none;
 margin: 0;
}

input[type=number]{
 -moz-appearance:textfield;
}

.breadcrumb
{
 display: flex;
 flex-direction: column;
}

.container-btn
{
 align-items: center;
 display: flex;
 flex-direction: column;
 flex-wrap: wrap;
 justify-content: center;
}

.container-row
{
 background-color: $white;
 padding: .5rem;
 margin: .8rem 0;
}

.container-search
{
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 justify-content: flex-start;
 margin: 1rem auto;
 padding: 0;
}

.cursor-pointer{
 cursor: pointer;
}

.form-error
{
 color: $red;
 margin: 0;
 padding: 0;
 text-align: center;
}

.form-label
{
 align-items: center;
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 justify-content: center;
 margin: 0;
 padding: 0;
 width: 100%;
}

.input-invoice
{
 height: 0.1px;
 opacity: 0;
 overflow: hidden;
 position: absolute;
 width: 0px;
 z-index: -1;
}

.label-invoice
{
 background-color: $white;
 border: 1px solid $purple;
 color: $purple;
 cursor: pointer;
 font-size: 1rem;
 font-weight: 700;
 line-height: 1.5;
 margin: 0;
 padding: 0.1rem 0.75rem;
 transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 width: 100%;
}

.label-invoice:hover
{
 background-color: $purple;
 border-color: rgba($purple, 0.5);
 color: $white;
}

.label-invoice:focus, .label-invoice.focus{
 box-shadow: 0 0 0 0.2rem rgba($purple, 0.5);
}

.section-square
{
 align-items: center;
 display: flex;
 flex-direction: column;
 flex-wrap: wrap;
 justify-content: center;
 margin: 1rem 0;
 padding: 0;
}

.selected
{
 background-color: $orange;
 font-weight: bolder;
}

.text-purple{
 color: $purple;
}

.user .profile .cover-image{
 background-image: url('https://placeimg.com/1200/300/nature') !important;
}

.w-10{
 width: 10%;
}

.w-15{
 width: 15%;
}

.w-20{
 width: 20%;
}
